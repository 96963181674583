/* Style for the main wrapper */
.main-wrapper {
    margin: 0 auto;
    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 10px;
  }
  
  /* Style for the form */
  .--form-control {
    background-color: white;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 20px;
  }
  
  /* Style for the form inputs */
  input,
  select {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border-radius: 5px;
    border: none;
    margin-bottom: 10px;
  }
  
  /* Style for the form labels */
  label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  /* Style for the form button */
  button {
    display: block;
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 5px;
    border: none;
    margin-top: 20px;
  }
  
  /* Style for the carousel */
  .carousel-container {
    width: 100%;
    margin-top: 20px;
  }
  
  /* Style for the carousel dots */
  .slick-dots li button:before {
    color: #007bff;
  }
  
  /* Style for the selected carousel dot */
  .slick-dots li.slick-active button:before {
    color: #007bff;
  }
  
  /* Style for the image list item bar */
  .MuiImageListItemBar-titleWrap {
    display: flex;
    flex-direction: column;
  }
  
  /* Style for the chip */
  .MuiChip-root {
    background-color: #007bff;
    color: white;
    margin: 5px;
  }
  
  /* Style for the message textarea */
  textarea {
    width: 100%;
    height: 100px;
    padding: 10px;
    font-size: 16px;
    border-radius: 5px;
    border: none;
    margin-bottom: 10px;
  }
  
  
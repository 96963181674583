@import url("https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&display=swap");
.mobile-nav {
  width: 100vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* background: rgba(0, 0, 0, 0.25);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border: 1px solid rgba(255, 255, 255, 0.18); */
  /* background-color: rgba(0, 0, 0, 0.678); */
  background-color: white;
  position: fixed;
  top: 0;
  /* z-index: 10; */
  color: white;

  z-index: 10;
  color: black;

  z-index: 10;
  color: black;
  height: 9vh;
}

.mobile-right-flex {
  display: flex;
  justify-content: space-between;
  width: 30%;
  align-items: center;
}

.mobile-cart,
.mobile-menu,
.close-mobile-menu {
  padding: 0.5rem;
  margin: 0.5rem;
  cursor: pointer;
}

.display-mobile-nav {
  height: 100vh;
  width: 80vw;
  display: flex;
  justify-content: space-between;
  background-color: rgb(221, 221, 221);
  transition: all 1s ease;
  position: fixed;
  z-index: 100000;
  top: 0;
  left: 0;
}

.mobile-nav-links {
  display: flex;
  flex-direction: column;
  margin: 2rem;
}

.mobile-nav-links > * {
  margin: 1rem;
}

.Navbar {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 9vh;
  background-color: white;
  backdrop-filter: blur(20px);
  position: fixed;
  top: 0;
  z-index: 10;
  color: black;
  /* font-family: "Catamaran", cursive; */
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 500;
}
.Navbar h1,
.Navbar p {
  font-size: 2.5vh;
}

.Navbar__left {
  display: flex;
  flex: 0.25;
  align-items: center;
  justify-content: space-around;
}
.Navbar__right {
  display: flex;
  flex: 0.75;
  align-items: center;
  justify-content: space-around;
  box-sizing: border-box;
  /* padding-left: 7vw; */
}
.Navbar__right .search-bar {
  flex: 0.6;
  height: 5vh;
}
.Navbar__right__right {
  display: flex;
  flex: 0.2;
  align-items: center;
  justify-content: space-around;
}
.cat {
  cursor: pointer;
  /* margin-left: 4vh; */
}
.cat:hover {
  cursor: pointer;
}
.alter {
  background-color: #fcfbfa;
}
.Logo {
  font-size: 3vh;
  font-weight: 600;
  font-family: "Libre Baskerville", serif;
}
.Logo span {
  font-weight: 300;
}

.cart {
  position: relative;
}
.mobile-logo {
  margin-left: 9vw;
}

/*

.cart::before {
  position: absolute;
  content: "10";
  color: white;
  padding: 0.1rem;
  border-radius: 50%;
  background-color: rgb(189, 153, 189);
  left: 10px;
  top: -15px;
} */

@media screen and (max-width: 768px) {
  .cat {
    display: none;
  }
  .Navbar__left {
    flex: 0.15;
  }
  .Navbar__right .search-bar {
    flex: 0.2;
  }
}

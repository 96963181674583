.title {
    background-color: #333;
    color: #fff;
    padding: 40px;
    text-align: center;
  }
  
  p {
    font-size: 1.2rem;
    margin-top: 10px;
  }
  
.image-list-grid-slider {
  width: 100%;
  height: auto;
  overflow: hidden;
  position: relative;
}

.slider-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  position: relative;
  transition: transform 0.5s ease-in-out;
}


.slide img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.slide-text {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.slide:hover .slide-text {
  opacity: 1;
}

.slide-text h2 {
  font-size: 1.5rem;
  margin: 0 0 5px 0;
}

.slide-text p {
  font-size: 1rem;
  margin: 0;
}

.nav-buttons-container {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.prev-button,
.next-button {
  width: 30px;
  height: 30px;
  margin: 0 10px;
  border: none;
  background-color: #fff;
  color: #333;
  font-size: 1.2rem;
  cursor: pointer;
}

.prev-button:hover,
.next-button:hover {
  background-color: #333;
  color: #fff;
}

@media screen and (max-width: 992px) {
  .slide {
    width: calc(100% / 2);
  }

  .nav-buttons-container {
    left: 5px;
  }

  .prev-button,
  .next-button {
    font-size: 1rem;
    width: 20px;
    height: 20px;
    margin: 0 5px;
  }
}

@media screen and (max-width: 768px) {
  .slide {
    width: 100%;
  }
}

@media screen and (max-width: 576px) {
  .slide {
    padding: 5px;
  }

  .nav-buttons-container {
    display: none;
  }
}

.slides-container {
  display: flex;
  flex-wrap: nowrap;
  transition: transform 0.3s ease-in-out;
}

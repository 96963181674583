.sub_cat  {
  margin-right: 2px !important;
  list-style: none;
  display: block;
  font-size: 14px;
  text-decoration: none;
  color: #282c3f;
  line-height: 23px !important;
  font-weight: 400;
  cursor: pointer;
}

.sub_cat :hover {
  font-weight: 500;
  color: #282c3f;
  cursor: pointer;
}


.sub_cat  li {
    text-indent: .5rem;

}





.user-profile {
  position: relative;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.login-icon {
  text-align: center;
}

.login-icon > p {
  font-size: 1rem;
}

.user-avatar {
  width: 9rem;
  position: absolute;
  color: rgb(0, 0, 0);
  background: #fff;
  top: 50px;
  left: 0;
  right: 20;
  padding: 10px;
  padding-bottom: 0;
  box-shadow: 0px 3px 42px -1px rgba(136, 136, 136, 0.66);
}

.user-avatar p {
  font-size: 1.1rem;
  padding-bottom: 10px;
}

.MuiSvgIcon-root {
  font-size: large !important;
}

/* Style for the table */
.table {
    border-collapse: collapse;
    width: 100%;
}

.table th,
.table td {
    padding: 8px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

.table th {
    background-color: #f2f2f2;
    color: #333;
}

.table tr:hover {
    background-color: #f5f5f5;
}

/* Style for the pagination */
/* .pagination {
    display: inline-block;
    margin: 10px 0;
} */

.pagination a {
    color: black;
    float: left;
    padding: 8px 16px;
    text-decoration: none;
    border: 1px solid #ddd;
}

.pagination a.active {
    background-color: #4CAF50;
    color: white;
    border: 1px solid #4CAF50;
}

.pagination a:hover:not(.active) {
    background-color: #ddd;
}

.Pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;

  }